@require '_variables'

$logo-size = 40px

.logo
  margin 0 auto
  width $logo-size
  height $logo-size
  background-position center center
  background-repeat no-repeat
  background-size $logo-size
  background-image url($path-images + '/logo.svg')
