@require '_variables'
@require '_mixins'

.code-view-summary
  .code-weight-badge,
  .github-button-container
    display inline-block
  .code-weight-badge
    margin-top 10px
    & + .github-button-widget
      left 10px
  .github-button-container
    position relative
    top 5.5px
  .ad-banner
    margin-top 40px

.code-view-summary-custom-content
  margin-top 30px
  a
    &:hover,
    &:focus,
    &:active
      border-bottom-color transparent

.code-view-summary-banner
  display inline-block
  padding 8px 10px 5px
  color $color-grey-dark
  border 1px solid $color-grey
  border-radius(5px)
  .icon
    margin-right 5px
