@require '_mixins'

.demo-demo
  margin 0 auto
  max-width 550px

.demo-demo-previewer
  height 400px

+breakpoint('xs')
  .demo-demo
    margin 60px 0
