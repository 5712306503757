@require '_variables'
@require '_mixins'

$font-size = 13px

.editor
  position relative
  height 400px
  background-color $color-grey-light
  border-radius(3px)
  .codeflask
    background transparent
  .codeflask__flatten
    font-size $font-size
    word-wrap normal
  .codeflask__textarea
    color transparent
  .token
    &.class-name
      color $color-blue
    &.constant
      color $color-blue
    &.property
      color $color-blue
    &.symbol
      color $color-blue
  pre
    padding 10px
  code[class*=language-],
  pre[class*=language-]
    background none
    color inherit
    font-size $font-size
    line-height 20px
