@require '_variables.styl'
@require '_mixins.styl'

.app-menu
  .list
    display flex
  .list-item
    &:not(:first-child)
      margin-top 0
      margin-left 35px
  a
    color $color-grey-dark
    font-family 'Fjalla One', Helvetica, Arial, sans-serif
    font-size $font-size-xs
    text-transform uppercase
    text-decoration none
    &:hover,
    &:focus,
    &:active
      color $color-grey-dark
      border-bottom-color $color-grey-dark
    &.router-link-active
      color $color-primary
      cursor default
      border-bottom-color transparent
    .icon
      margin-right 5px
      color inherit
      font-size $font-size-sm
