@require '_variables'
@require '_mixins'

.btn
  position relative
  margin 0
  padding 0 15px
  color $color-grey-dark
  background-color transparent
  font-size $font-size-sm
  text-align center
  line-height 35px
  border-width 1px
  border-style solid
  border-color $color-grey
  cursor pointer
  webkit-appearance(none)
  box-sizing(border-box)
  transition(all, 300ms)
  border-radius(22px)
  unselectable()
  +highlight()
    outline 0
    background-color $color-grey
    color $color-lightest
  &.btn-primary
    color $color-blue
    border-color $color-blue
    +highlight()
      background-color $color-blue
      color $color-lightest
  &.btn-danger
    color $color-red
    border-color $color-red
    +highlight()
      background-color $color-red
      color $color-lightest
  &.btn-small
    padding 0 10px
    line-height 25px
    font-size $font-size-xxs
    .icon
      margin-right 5px
