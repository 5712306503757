@require '_variables'
@require '_mixins'
@import url('https://fonts.googleapis.com/css?family=Fjalla+One');

@font-face {
  font-family: 'Fjalla One';
  font-display: swap;
}

html, body, main, article
  margin 0

html, body
  color $color-grey-dark
  font-family $font-family-primary
  font-size 16px
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale

h1, h2, h3, h4, h5, h6
  margin 0
  color $color-primary
  font-family $font-family-secondary
  font-weight normal
  text-transform uppercase

p
  margin 0
  line-height 1.2

a
  display inline-block
  color $color-blue
  text-decoration none
  border-bottom-width 1px
  border-bottom-style solid
  border-bottom-color transparent
  transition(all, 750ms)
  &:hover,
  &:focus,
  &:active
    color $color-blue
    border-bottom-color $color-blue

ul
  margin 0
  padding 0
  list-style-type none
