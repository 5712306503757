@require '_variables'

.copy-btn
  .ion-ios-checkmark
    color $color-green
    & + .copy-btn-feedback-text
      color $color-green
  .ion-ios-close
    color $color-red
    & + .copy-btn-feedback-text
      color $color-red
