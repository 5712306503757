@require '_mixins'

.btn-group
  display inline-block
  clearfix()
  .btn
    float left
  .btn
    &:not(:first-child)
      border-radius(0 22px 22px 0)
  > *
    &:not(:first-child)
      .btn
        border-radius(0 22px 22px 0)
  .btn
    &:not(:last-child)
      border-right 0
      border-radius(22px 0 0 22px)
  > *
    &:not(:last-child)
      .btn
        border-right 0
        border-radius(22px 0 0 22px)
